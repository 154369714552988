import { HttpTool } from "../tools/Http"

export type XLAnmeldung = {
    id?: number,
    
    anrede: string,
    rufname: string,
    familienname: string,
    geburtsname: string,
    abiturjahrgang: number,
    akademischerGrad: string,
    istMitglied: boolean,

    anrede1: string,
    rufname1: string,
    familienname1: string,
    geburtsname1: string,
    abiturjahrgang1: number,
    akademischerGrad1: string,
    istMitglied1: boolean,

    anrede2: string,
    rufname2: string,
    familienname2: string,
    geburtsname2: string,
    abiturjahrgang2: number,
    akademischerGrad2: string,
    istMitglied2: boolean,

    anzahlKinder: number,

    tel: string,
    mail: string,
    anschrift: string,

    hat_gezahlt?: boolean,
    bestaetigt?: boolean,
    zustimmungZurSpeicherung: boolean,

    unkostenbeitrag?: string
}

export type XXLAnmeldungResponse = {
    status: string,
    message: string
}

export type XLSettings = {
    betragMitglieder: number,
    betragNichtmitglieder: number,
    betragKinder: number,
    datum: string,
    enabled: boolean
}


export class XLTreffenData {
    public static async fetchSettings(): Promise<XLSettings> {

        let settings: XLSettings = await HttpTool.getSettings('xl-treffen');

        if(settings == null){
            settings = {
                datum: "----",
                betragMitglieder: 0,
                betragNichtmitglieder: 0,
                betragKinder: 0,
                enabled: false
            }
        }

        return settings;
    }
 
    public static async setSettings(settings: XLSettings): Promise<boolean>{
        return await HttpTool.setSettings('xl-treffen', JSON.stringify(settings));
    }

}
